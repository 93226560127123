<template>
    <section class="section-container">
        <div class="section-container-wrap">
            <Sidebar />
            <v-content class="ma-0">
                <v-container class="pa-0" fluid>
                    <router-view />
                </v-container>
            </v-content>
        </div>
    </section>
</template>

<script>
import Sidebar from "../components/Sidebar/Sidebar";
export default {
    components: {
        Sidebar,
    },
};
</script>

<style scoped>
.section-container {
    background: #fff;
    width: 100%;
    box-sizing: border-box;
}

.section-container-wrap {
    padding: 0;
    margin: 0 auto;
}
</style>
